<template>
  <!-- Add product (sidebar) -->
  <b-sidebar :id="id" :title="title" right backdrop shadow>
    <div class="col desc-column">
      <p>{{$t('contact-list-component.custom-field.description')}}</p>
    </div>
    <form @submit.prevent="save" class="create-custom-field col mb-0">
      <!-- Options -->
      <div v-if="hasAddNew">
        <!-- Name -->
        <div class="input-group">
          <label for="">{{$tc('app.title', 1)}} <!-- <span class="material-symbols-outlined"> info </span> --></label>
          <input v-model="form.name" type="text" class="form-control" required placeholder="" />
        </div>

        <!-- Description -->
        <div class="input-group">
          <label for="">{{$t('app.description')}} <!-- <span class="material-symbols-outlined"> info </span> --></label>
          <textarea id="description-area" v-model="form.description" rows="4" class="form-control" placeholder=""/>
          <!-- <code>{{form.description}}</code> -->
        </div>
        <span class="title-group">{{$t('options-div.title')}}</span>
        <div class="input-group">
          <div class="control-group opt-2 d-flex align-items-center">
            <label class="control control-checkbox">
              <input id="department-modal-active" v-model="form.requiredField" type="checkbox" />
              <div class="control_indicator"></div>
            </label>
            <span>{{$t('contact-list-component.custom-field.required')}}</span>
          </div>
        </div>

        <div class="input-group">
          <div class="control-group opt-2 d-flex align-items-center">
            <label class="control control-checkbox">
              <input v-model="form.sensitiveContent" type="checkbox" />
              <div class="control_indicator"></div>
            </label>
            <span>{{$t('contact-list-component.custom-field.sensitive')}}</span>
          </div>
        </div>

        <div class="input-group">
          <div class="control-group opt-2 d-flex align-items-center">
            <label class="control control-checkbox">
              <input v-model="form.appearsOmni" type="checkbox" />
              <div class="control_indicator"></div>
            </label>
            <span>{{$t('contact-list-component.custom-field.omni')}}</span>
          </div>
        </div>
        <button v-b-toggle="id" @click="hasAddNew = false" class="btn btn-outline-primary">{{$t('generic-str.cancel')}}</button>
        <button :disabled="isSending" type="submit" :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }" class="btn btn-primary ml-auto to-right">{{$t('generic-str.save')}}</button>
      </div>
      <div class="input-group custom-fields-group" v-else>
        <div class="btn-add-new-field" @click="hasAddNew = true">
          + Adicionar novo
        </div>
        <ul class="list-unstyled custom-field-list">
          <li v-for="(customField, index) in customFields" :key="index"><span class="material-symbols-outlined drag-indicator">drag_indicator</span> {{customField.name}} <span @click="deleteCustomField(customField.id, customField.name)" role="button" class="material-symbols-outlined delete-icon">delete_forever</span></li>
        </ul>
      </div>
    </form>
  </b-sidebar>
  <!--/Add product (sidebar) -->
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
import axios from '@/plugins/axios';
import Swal from 'sweetalert2';
// import { VueTelInput } from 'vue-tel-input';

export default {
  components: {
    // VueTelInput,
  },
  props: ['title', 'id', 'customFields'],
  data() {
    return {
      hasAddNew: false,
      customToggle: false,
      groups: [],
      isSending: false,
      mobile_number: null,
      invalidPhone: false,
      countryCode: '',
      form: {
        name: '',
        description: '',
        requiredField: false,
        sensitiveContent: false,
        appearsOmni: true,
      },
    };
  },
  methods: {
    save() {
      if (Object.values(this.customFields).some((el) => el.name === this.form.name)) {
        this.$toast.show({
          title: 'Campo personalizado existente',
          content: 'Defina outro nome',
          type: 'danger',
        });
      } else if (this.form.name) {
        this.isSending = true;
        // const dataSend = this.data;
        this.hasAddNew = false;
        axios().post('/contacts/custom-field', this.form).then(() => {
          this.isSending = false;
          this.$emit('created');
          // this.hide();
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Criado campo personalizado',
            type: 'success',
          });
          this.form.name = '';
        }).catch((ee) => {
          const content = ee?.response?.data?.message ?? String(ee);

          this.isSending = false;
          this.$toast.show({
            title: 'Campo personalizado',
            content,
            type: 'danger',
          });
        });
      } else {
        this.$toast.show({
          title: 'Campo personalizado',
          content: 'Falta definir o nome',
          type: 'danger',
        });
      }
    },
    deleteCustomField(id, name) {
      console.log('customField id to delete: ', id);
      Swal.fire({
        title: `${this.$t('contact-list-component.custom-field.swal.remove-custom-field')} '${name}' ?`,
        text: this.$t('contact-list-component.custom-field.swal.sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
        cancelButtonText: this.$t('generic-str.no'),
      }).then(
        (result) => {
          if (result.value) {
            axios().delete(`/contacts/custom-field/${id}`).then((response) => {
              console.log('Delete response: ', response);
              this.isSending = false;
              // this.hide();
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Campo deletado com sucesso!',
                type: 'success',
              });
              // this.toggleSidebar(this.id);
              this.form.name = '';
              this.$root.$emit('getCustomFields'); // Refresh customField list
            }).catch((ee) => {
              const content = ee?.response?.data?.message ?? String(ee);

              this.isSending = false;
              this.$toast.show({
                title: 'Campo personalizado',
                content,
                type: 'danger',
              });
            });
          }
        },
      );
    },
    toggleSidebar(id) {
      this.$root.$emit('bv::toggle::collapse', id);
    },
  },
};
</script>
<style scoped lang="scss">
/* Sidebar add prod */
::v-deep .b-sidebar {
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }

  .b-sidebar-header {
    padding-left: 2rem;
    padding-right: 2rem;
    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }
  .b-sidebar-body {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
  }
  header {
    >strong {
      order: -1;
    }
  }
  .desc-column {
    p {
      color: var(--gray-font-color);
    }
  }
  textarea, select, .form-control {
    border-radius: 8px;
  }

  .create-custom-field {
    display: flex;
    flex-wrap: wrap;

    button {
      border-radius: 8px;
      width: fit-content;
    }

    .custom-fields-group {
      span {
        font-weight: 400;
      }
      .toggle-custom {
        display: flex;
        align-items: center;
        width: 100%;
         .material-symbols-outlined {
          margin-left: auto;
          margin-right: 5px;
          transition: .5s;
          &.rotate {
            transform: rotate(-180deg);
          }
         }
      }
      li {
        display: flex;
        align-items: center;
        padding: 0 .5rem;
      }
      .delete-icon {
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
        color: #D5273E;
        margin-left: auto;
        font-size: 18px;
        transition: .5s;

        &:hover, &:focus {
          transform: scale(1.2);
        }
      }
    }

    .input-group {
      display: flex;
      margin-bottom: 1.5rem;
      label {
        display: flex;
        align-items: center;
        gap: .5rem;
      }
      label:not(label.control) {
        width: 100%;
      }
      label.control ~ span {
        font-weight: 400;
        font-size: .875rem !important;
        margin-left: .5rem;
        color: var(--gray-font-color-6);
      }
    }
    .material-symbols-outlined {
      color: var(--gray-font-color);
    }
    .title-group {
      font-weight: 400;
      color: var(--gray-font-color-6);
      margin-bottom: 1rem;
    }
    .custom-field-list {
      width: 100%;
      li {
        margin-bottom: .5rem;
        color: var(--gray-font-color-2);
        font-weight: 400;
        padding: 10px 8px 10px 8px;
        border-radius: 8px;
        border: solid 1px #E4E7EC;
      }
    }
  }
}
.drag-indicator {
  color: #E4E7EC !important;
  margin-right: 5px !important;
}
.btn-add-new-field {
  padding: 13px 0px 13px 0px;
  border: dashed 1px  #6F6F6F;
  margin-top:20px;
  margin-bottom: 20px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  color: #919191;
  cursor: pointer;
}
.btn-add-new-field:hover {
  background: #efeded;
}
</style>
